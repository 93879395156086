import { mapGetters } from 'vuex';
import RowProgram from "@/views/program/RowProgram.vue";
import * as Constants from '@/utils/crm/crm-constants';


const defaultFilters = () => ({
    search: '',
    filters: {
        toggles: [],
        etat: null,
    },
    order: { attribute: 'name', descending: false, },
    page: 1,
    pagingCookie: null,
});


export default {
    components: {
        RowProgram,
    },
    data() {
        const data = {
            programs: [],
            toggles: {
                toggles: [
                    {
                        id: 'comm',
                        label: 'Commercialisés',
                    },
                    {
                        id: 'lanc',
                        label: 'Lancés < 2 ans',
                    },
                    {
                        id: 'queu',
                        label: 'Queue de prog.',
                    },
                ],
                etat: Constants.PV_ETAT.filter(etat => [
                    Constants.PV_ETAT_ETUDEPRECOM,
                    Constants.PV_ETAT_PREPAR,
                    Constants.PV_ETAT_COMMER,
                    Constants.PV_ETAT_STOPPE,
                    Constants.PV_ETAT_VENDU,
                ].includes(etat.id))
            },
            moreRecords: false,
            totalRecordCount: 0,
            SMIloaded: null,
        };
        Object.assign(data, defaultFilters());
        return data;
    },
    computed: {
        ...mapGetters({
            loading: 'loading/loading',
            SMIKey: 'common/SMIKey',
        }),
        toggleAllSelected(): boolean {
            return !this.filters.toggles.length;
        },
    },
    methods: {
        resetFilters() {
            Object.assign(this.$data, defaultFilters());
            this.refresh();
        },
        getPage() {
            if (this.loading) return;
            const payload: any = {
                page: this.page,
                search: this.search,
                toggles: this.filters.toggles.join(','),
                order: this.order.attribute,
                desc: this.order.descending,
            };
            if (this.filters.etat) {
                payload.statuscode = this.filters.etat;
            }
            if (this.pagingCookie) {
                payload.pagingCookie = this.pagingCookie;
            }
            return this.$store.dispatch('program/getList', payload).then(response => {
                // console.log(response.data);
                this.pagingCookie = response.pagingCookie;
                this.moreRecords = response.isMoreRecords;
                this.totalRecordCount = response.totalCount;
                return response.data;
            });
        },
        appendNextPage() {
            this.page++;
            this.getPage().then((programs: any) => {
                this.programs = this.programs.concat(programs);
            });
        },
        refresh() {
            this.page = 1;
            this.pagingCookie = null;
            this.getPage().then((programs: any) => {
                this.SMIloaded = this.SMIKey;
                this.programs = programs;
            });
        },
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (vm.SMIloaded !== vm.SMIKey) vm.refresh();
        });
    },
};
