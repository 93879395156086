<style lang="scss">
@import "./PlanVente";
</style>

<template>
  <div id="planvente">
    <Header>Plan de vente</Header>
    <form class="p-3 pb-0 p-md-4 pb-md-0" @submit.prevent="refresh">
      <div class="d-xl-flex">
        <div class="d-flex flex-grow-1 me-xl-2">
          <input
            type="text"
            class="form-control search_input"
            placeholder="Rechercher"
            v-model="search"
          />
        </div>
        <div class="d-flex text-center mt-1 mt-xl-0">
          <div v-if="false">
            <template v-for="toggle in toggles.toggles" :key="'in_' + toggle.id">
              <input
                type="checkbox"
                class="btn-check"
                :id="toggle.id"
                autocomplete="off"
                :value="toggle.id"
                v-model="filters.toggles"
                @change="refresh"
              />
              <label
                class="btn btn-info active filter_label me-1"
                :for="toggle.id"
                >{{ toggle.label }}</label
              >
            </template>
          </div>
          <select
            class="form-select me-1"
            v-model="filters.etat"
            @change="refresh"
          >
            <option :value="null">État</option>
            <option disabled>──────────</option>
            <option
              v-for="option in toggles.etat"
              :key="option.ud"
              :value="option.id"
            >
              {{ option.label }}
            </option>
          </select>
          <button
            type="button"
            class="btn btn-secondary me-1"
            @click="resetFilters"
          >
            &#10799;
          </button>
          <button type="submit" class="btn btn-primary">
            Filtrer
          </button>
        </div>
      </div>
    </form>
    <div>
      <div class="p-3 p-md-4 d-flex align-items-center">
        <h5 class="m-0" v-if="!loading">
          <span class="text-info">Programmes de vente :</span>
          {{ totalRecordCount }}
        </h5>
        <h5 v-else>Chargement ...</h5>
        <div class="ms-auto d-flex text-nowrap align-items-center">
          <span class="fw-light me-2">Trié par</span>
          <select class="form-select" v-model="order.attribute" @change="refresh">
            <option value="name">Nom</option>
            <option value="esi">Référence</option>
            <option value="lanc">
              Date de lancement
            </option>
          </select>
          <div class="btn" @click="order.descending = !order.descending;refresh()">
            <fa
              :icon="order.descending ? 'sort-amount-up' : 'sort-amount-down'"
            />
          </div>
        </div>
      </div>
      <div class="px-3 pb-3 px-md-4 pb-md-4">
        <div class="row row-cols-1 g-3 g-md-4">
          <div class="col" v-for="program in programs" :key="program.id">
            <RowProgram :program="program" />
          </div>
        </div>
      </div>
      <div class="text-center mb-2">
        <button v-if="moreRecords"
          :class="[
            'btn',
            'btn-primary',
            'ms-2',
            { disabled: loading },
          ]"
          @click="appendNextPage"
        >
          Voir plus
        </button>
      </div>
    </div>
  </div>
</template>

<script src="./PlanVente.ts" />
