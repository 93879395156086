import { dateFormatter } from "@/utils/crm-mixin";
import IndicatorBlock from "@/views/common/IndicatorBlock.vue";
import ProgressRing from "@/views/common/ProgressRing.vue";

export default {
    components: {
        IndicatorBlock,
        ProgressRing,
    },
    props: ["program"],
    mixins: [ dateFormatter ],
    computed: {
        eiInfoNb() {
            return (this.program.mso_codepostalid ? 1 : 0)
                + (this.program.mso_ville ? 1 : 0);
        }
    },
};
