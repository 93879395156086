import { crmFormat as cf } from "@/utils/crm/crm";

export function crmFormatter(crmEntity) {
    return {
        methods: {
            cf(field, undefinedLabel = null) {
                if (typeof this[crmEntity] === 'undefined') {
                    return console.error(`CRM entity "${crmEntity}" not found`);
                }
                return cf(this[crmEntity], field, undefinedLabel);
            },
        }
    };
}

export const dateFormatter = {
    methods: {
        df(date?:Date) {
            if (!date) return null;
            return (new Date(date)).toLocaleDateString();
        },
    }
}
