<template>
  <router-link
    :to="{
      name: 'program',
      params: { id: program.qua_programmedeventeid },
    }"
    class="rowcard"
  >
    <div class="card">
      <div class="row g-0">
        <div class="col-sm-2 program_image">
          <img
            v-if="program.mso_photoprincipalpcf"
            :src="'data:image/jpeg;base64,' + program.mso_photoprincipalpcf"
          />
          <fa v-else icon="image" />
          <div class="program_ref text-truncate">
            <span class="opacity7">Référence :</span>
            {{ program.qua_esi || "vide" }}
          </div>
        </div>
        <div class="col-sm-10">
          <div class="card-body">
            <div class="d-md-flex">
              <h5 class="card-title text-truncate mb-3 flex-grow-1">
                {{ program.qua_name }}
              </h5>
              <div class="text-truncate ps-2 d-none d-md-block">
                {{ program.statuscode_label }}
              </div>
            </div>
            <div class="row">
              <div class="col-sm-8">
                <div class="row">
                  <div class="col-md-6">
                    <div class="text-truncate mb-3 d-md-none">
                      {{ program.statuscode_label }}
                    </div>
                    <div class="mb-3 text-truncate">
                      <fa icon="map-marker" class="fw-light me-1" />
                      <span v-if="!eiInfoNb" class="ms-1"
                        >Adresse non définie</span
                      >
                      {{ program.mso_codepostalid_label }}
                      <span v-if="eiInfoNb === 2">-</span>
                      {{ program.mso_ville }}
                    </div>
                    <div class="mb-3 text-truncate">
                      <fa icon="building" class="fw-light me-1" />
                      {{ program.qua_totallogementsrollup }} logements /
                      {{ program.qua_totallogementsvendusqtrollup }} ventes
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <fa
                        icon="calendar"
                        class="fw-light me-1"
                        title="Date de lancement de commercialisation"
                      />
                      {{
                        df(program.qua_datelancementcommercialisation) ||
                        "Indéterminée"
                      }}
                    </div>
                    <div class="mb-3 mb-md-0">
                      <span
                        class="badge badge_primary_outline"
                        v-if="program.qua_aful"
                        >AFUL</span
                      >
                      <span
                        class="badge badge_primary_outline"
                        v-if="program.qua_asl"
                        >ASL</span
                      >
                      <span
                        class="badge badge_primary_outline"
                        v-if="program.qua_copro"
                        >COPRO</span
                      >
                    </div>
                  </div>
                </div>
                <div class="mb-3 mb-md-0 text-truncate">
                  <span class="fw-light">Mandant :</span>
                  {{ program.qua_nomdumandant_label }}
                </div>
              </div>
              <div class="col-sm-4 d-sm-flex justify-content-center">
                <div class="row w-100">
                  <div class="col-6 col-sm-12 col-md-6 indicator">
                    <div class="indicator_title">Chiffre d'affaires</div>
                    <div class="indicator_body">
                      <div class="indicator_col">
                        <IndicatorBlock
                          :value="program.mso_ca"
                          :money="true"
                        />
                        <IndicatorBlock
                          :value="program.mso_caannee"
                          :money="true"
                          class="text-info"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-sm-12 col-md-6 indicator">
                    <div class="indicator_title">Écoulement</div>
                    <div class="indicator_body">
                      <ProgressRing :percent="program.qua_tauxecoulement" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script src="./RowProgram.ts" />
